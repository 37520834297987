import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WhyUseCommercial = () => {

    return (

        <section className="why-use-section bg-yellow">
            <Container>
                <Row style={{paddingBottom: '1rem'}}>
                    <Col>
                        <h2>Why use Bright and Clean for your Commercial Cleaning Needs</h2>
                    </Col>
                </Row>
                <hr style={{border: '1px solid #AED9E0', width: '10%', margin: '0px'}}/>
                <Row style={{paddingTop: '1rem'}}>
                    <Col>
                        <p className="text-justify">For the employees that work at your facility and the customers or visitors that enter it, the cleanliness of your commercial business offers the first impression of your operation. As a business owner, you have numerous issues to manage every day.</p>
                        <p className="text-justify">Your focus needs to be on ensuring that your business grows and thrives. When you leave your commercial cleaning needs to Bright and Clean, no matter who enters your facilities, you can be sure your place of business is clean, fresh, comfortable and safety disinfected.</p>
                        <p className="text-justify">With Bright and Clean, you can count on the promise of knowing that a reliable commercial cleaning agency will promptly arrive at your facility at the scheduled time with all the proper equipment and supplies. Our diligent and hard-working staff stands ready to handle every aspect of your regular cleaning maintenance. Our commercial cleaning services company also has the knowledge, equipment, and supplies to handle an unexpected urgent clean-up matter and specialty services like deep cleaning, disinfecting, and sanitation.</p>
                    </Col>
                </Row>
            </Container>
        </section>


    )
}

export default WhyUseCommercial