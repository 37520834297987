
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const CommercialServiceHero = () => {

    const data = useStaticQuery(
        graphql`
          query {
            commercial: file(relativePath: { eq: "commercial-service-hero.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )

      const imageData = data.commercial.childImageSharp.fluid
  
    return(
        <BackgroundImage Tag="section" fluid={imageData} style={{minHeight: '100vh'}}>
            <Container fluid>
            <Row className="services-box-padding"></Row>
                        <Row>
                            <Col></Col>
                                    <Col xs={10} lg={8}>
                                        <div style={{backgroundColor: '#F5F5F5', width:'100%', padding: '10px', opacity: '95%'}}>
                                            <Row className="text-center">
                                                <Col>
                                                    <h2>Our Commercial Cleaning Services</h2>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="text-center">
                                                <Col><p>Providing the most cost-effective commercial cleaning service possible, delivering exceptional professionalism and expertise with every job. We are able to clean any type and size of building, from restaurants through to multi-storey offices.</p></Col>
                                            </Row>
                                                                                       
                                  </div> 
                              </Col>
                            <Col></Col>
                          </Row>
                
            </Container>
            </BackgroundImage>
    )
   
  
}



export default CommercialServiceHero
