
import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

const GetInTouch = () => {
  
    return(
     
      
        <section style={{padding: '3rem 0', borderTop: '1px solid #EFEFEF', borderBottom: '1px solid #EFEFEF'}}>
            
            <Container>
                <Row >
                    <Col lg={8} id="get-in-touch"  style={{marginBottom: 'auto', marginTop: 'auto'}}> 
                       <h4>Get in touch with Bright and Clean</h4>
                    </Col>
                    <Col lg={4} className="text-center">
                    <Link to="/contact-us"><Button variant="outline-primary">Contact Us</Button></Link>
                        
                    </Col>
                </Row>
            </Container>
            
        </section>
      
       
    )
   
  
}



export default GetInTouch
