import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CommercialServiceHero from "../components/services/commercial/commerical-service-hero"
import CommercialServiceOfferings from "../components/services/commercial/service-offering"
import WhyUseCommercial from "../components/services/commercial/why-use"
import GetInTouch from "../components/get-in-touch"

const CommericalCleaningPage = () => (
  <Layout>
    <SEO title="Commercial Cleaning Services in Chichester" />
    <CommercialServiceHero/>
    <CommercialServiceOfferings/>
    <WhyUseCommercial/>
    <GetInTouch/>
    
  </Layout>
)

export default CommericalCleaningPage