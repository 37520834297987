
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

const CommercialServiceOfferings = () => {

    const data = useStaticQuery(
        graphql`
          query {
            office: file(relativePath: { eq: "office.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            industrial: file(relativePath: { eq: "industrial.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            school: file(relativePath: { eq: "schools.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        `
      )

      const officeCleaning = data.office.childImageSharp.fluid
      const industrialCleaning = data.industrial.childImageSharp.fluid
      const schoolCleaning = data.school.childImageSharp.fluid
  
    return(
        
        <section className="service-offerings-section">
            <Container>
              <Row className="service-offer">
                  <Col md={3}>
                      <Img fluid={officeCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">Office Cleaning</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">Providing a clean office environment is a key component of a modern, effective organisation. Cleanliness is of paramount importance to a company’s brand persona and client perception. It also has a major role to play for employee health and wellbeing and can directly influence productivity through the prevention of absences from work.</p>
                      <p className="text-justify">From reception area, offices, meeting rooms, washrooms to kitchens we will tailor your office cleans to your exact requirements including frequency, depth of clean, time of clean.</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
              <Row className="service-offer">
                  <Col md={3}>
                  <Img fluid={industrialCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">Industrial Cleaning</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">It’s important for restaurants to be clean and sanitary for the safety of food preparation. Patrons want to eat and enjoy a nice, clean atmosphere, and it will help keep customers coming back. Bright and Clean will clean the tables and chairs and scrub your restaurant from corner to corner. We will scrub your bathrooms and restock the supplies.</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
              <Row className="service-offer">
                  <Col md={3}>
                  <Img fluid={schoolCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">School Cleaning</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">Schools get a lot of foot traffic and they can be a breeding ground for germs and bacteria. Our school cleaning services offer the reliability and affordability you are looking for. From small nurseries, to multi-building comprehensive secondary schools and everything in between.</p>
                      <p className="text-justify">We have the flexibility and the understanding to work closely with you to form and deliver a school cleaning service program that works for your school’s needs, and which spares no expense on detail, right down to daily school cleaning services. We understand that daily cleaning needs to meet a high standard, and always make sure our staff are informed and prepared for entering a school environment.</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
                
            </Container>
        </section>
    )
   
  
}



export default CommercialServiceOfferings
